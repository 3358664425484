.antd-modal-custom .ant-modal-content {
  padding: 0;
}
.antd-modal-custom .ant-modal-header {
  padding: 1rem 2rem;
  border-bottom: solid 1px #e2e8f0;
}
.antd-modal-custom .ant-modal-close {
  right: 2rem;
}
.antd-modal-custom .ant-modal-body {
  padding: 0 2rem;
}
.antd-modal-custom .ant-modal-footer {
  display: flex;
  margin-top: 0;
  padding: 1.25rem 2rem 3.125rem 2rem;
  border-top: solid 1px #e2e8f0;
}
.antd-modal-custom .ant-btn {
  font-weight: 700;
}
